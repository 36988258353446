$color-white: #fff;
$color-grey: #888;
$color-black: #333;
$color-red: #ff5722;
$color-orange: #ff9800;
$color-yellow: #ffc107;
$color-green: #8bc34a;

@import 'timepiece/timepiece.scss';

html {
  height: 100%;
}

body {
  color: $color-black;
  font-family: 'Helvetica Neue', 'Arial', sans-serif;
}

header {
  margin: 0 0 2rem 0;
}

section {
  border-radius: 1rem;
  margin: 0 0 2rem 0;
  box-shadow: 0 0 3rem 1px rgba(0, 0, 0, 0.3);
}

canvas {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

h1 {
  display: inline-block;
  margin: 0;
  font-family: 'Helvetica', sans-serif;
  font-size: 1.4rem;

  line-height: 4rem;
}

h2 {
  margin: 0 0 1rem 0;
  font-size: 1.1rem;
  line-height: 1;
}

p {
  margin: 0;
  line-height: 1.6;
}

a {
  color: $color-black;
}

footer a {
  display: inline-block;
  padding: 1rem;
}

a:hover {
  color: $color-grey;
}

.page-wrapper {
  max-width: 80em;
  padding: 1em;
  margin: 0 auto;
}

.description-wrapper {
  padding: 2em;
}

.logo {
  display: inline-block;
  width: 4rem;
  height: 4rem;
  margin: 0 1rem 0 0;
  vertical-align: middle;
}

.profile-pic {
  width: 10rem;
  height: 10rem;
  border-radius: 10rem;
  background-image: url(images/profile.jpg);
  background-size: 100%;
  float: right;
}

.poster-wrapper {
  border-radius: 1rem;
  background-image: linear-gradient(62deg, #777 0%, #999 100%);
  text-align: center;
}

.poster {
  width: 35%;
  border: 0.5rem solid $color-black;
  margin: 2rem;
  box-shadow: 0 0 1rem 1px rgba(0, 0, 0, 0.3);
}

.button-wrapper {
  margin-left: 2rem;
}

button {
  width: 8rem;
  padding: 0.5rem;
  border: none;
  border-radius: 3px;
  margin-right: 2rem;
  background: $color-white;
  box-shadow: 0 0 0.1rem 1px rgba(0, 0, 0, 0.3);
  color: $color-black;
  cursor: pointer;
  font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
  font-size: 0.7rem;

  &:hover {
    box-shadow: 0 0 0.5rem 1px rgba(0, 0, 0, 0.3);
  }
}

footer {
  text-align: center;
}

$minColWidth: 20rem;
$gridGap: 2rem;

* {
  box-sizing: border-box;
}

.grid-wrap {
  padding: $gridGap;
}

.grid {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax($minColWidth, 1fr));

  &+.grid {
    margin-top: $gridGap;
  }
}

.column {
  display: flex;
  flex-direction: column;
}

.resume {
  .block {
    margin-bottom: 2rem;
  }
}

.landing {
  background-color: #21D4FD;
  background-image: linear-gradient(19deg, #21D4FD 0%, #B721FF 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  font-family: 'Helvetiva', sans-serif;

  h1 {
    font-size: 3rem;
    font-weight: 500;
    color: linear-gradient(19deg, #21D4FD 0%, #B721FF 100%);
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
    // opacity: 50%;
  }

  .bubble {
    background: rgba(255, 255, 255, 0.3);
    border-radius: 2rem;
    padding: 2rem;
    box-shadow: 0 0 3rem 1px rgba(0, 0, 0, 0.3);
  }
}
