.clock-wrapper {
  padding: 3em;
  background: url(images/retina-wood.png);
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.clock {
  position: relative;
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  background: $color-black;
}

.center {
  position: absolute;
  top: 47%;
  left: 47%;
  width: 6%;
  height: 6%;
  border-radius: 50%;
  background: $color-orange;
}

.hand {
  position: absolute;
  left: 49%;
  width: 2%;
  border-radius: 3px;
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}

#hours {
  top: 30%;
  height: 20%;
  background: $color-yellow;
}

#minutes {
  top: 10%;
  height: 40%;
  background: $color-red;
}

#seconds {
  top: 2%;
  height: 48%;
  background: $color-green;
}